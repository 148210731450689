<template>
  <base-modal
    :title="warningTitle"
    :sub-title="warningText"
    position="center"
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div>
        <ul class="description sub-text-s2 text-gray-second">
          <li>공유 또는 판매하고 싶은 콘텐츠 등록해주세요.</li>
          <li>
            Video Drm을 통해 동영상의 불법 복제 및 유통을 방지할 수 있어요.
          </li>
          <li>유튜브 동영상을 임베드할 수 있어요.</li>
        </ul>
        <hr class="hr" />
        <ul>
          <li>
            <input-checkbox
              class="check-box"
              label="유료 콘텐츠"
              :default-value="state.isPaid"
              @update-data="
                (checked) => actions.updateAccessType('isPaid', checked)
              "
            ></input-checkbox>
          </li>
          <li>
            <input-checkbox
              class="check-box"
              label="회원 전용 콘텐츠"
              :default-value="state.isUserOnly"
              @update-data="
                (checked) => actions.updateAccessType('isUserOnly', checked)
              "
            ></input-checkbox>
          </li>
          <hr class="hr" />
          <li>
            <input-checkbox
              class="check-box"
              label="DRM 동영상 업로드"
              :default-value="state.types.isVideoDrm"
              @update-data="
                (checked) => actions.updateType('VideoDrm', checked)
              "
            ></input-checkbox>
          </li>
          <li>
            <input-checkbox
              class="check-box"
              label="동영상 임베드"
              :default-value="state.types.isVideo"
              @update-data="(checked) => actions.updateType('Video', checked)"
            ></input-checkbox>
          </li>
          <li>
            <input-checkbox
              class="check-box"
              label="brunch stroy 공유"
              :default-value="state.types.isBrunch"
              @update-data="(checked) => actions.updateType('Brunch', checked)"
            ></input-checkbox>
          </li>
        </ul>
        <hr class="hr" />
      </div>
      <div class="btn-wrapper">
        <button-basic
          v-if="isCancelBtn"
          class="cancel-btn"
          :class="{ 'w-100': !isConfirmBtn }"
          :text="cancelText"
          color="#0D0D10"
          bg-color="#ECF1F4"
          text-size="s1"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          v-if="isConfirmBtn"
          class="confirm-btn"
          :class="{ 'w-100': !isCancelBtn }"
          :text="confirmText"
          text-size="s1"
          @action="actions.confirm()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive } from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputCheckbox from "@/components/console/inputs/InputCheckbox.vue";

export default {
  name: "ContentsSettingInfoModal",
  components: { InputCheckbox, ButtonBasic, BaseModal },
  props: {
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: "취소",
    },
    warningTitle: {
      type: String,
      default: "",
    },
    warningText: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      required: true,
    },
    isCancelBtn: {
      type: Boolean,
      default: true,
    },
    isConfirmBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["confirm", "hideModal"],
  setup(props, { emit }) {
    const state = reactive({
      types: {
        isVideoDrm: false,
        isVideo: false,
        isBrunch: false,
      },
      isPaid: false,
      isUserOnly: false,
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      confirm: () => {
        let payload = {};

        if (state.isPaid) {
          payload.isPaid = true;
        }

        if (state.isUserOnly) {
          payload.isUserOnly = true;
        }

        if (state.types.isVideo) {
          payload.type = 3;
        }

        if (state.types.isBrunch) {
          payload.type = 5;
          payload.channelCode = "brunch";
        }

        if (state.types.isVideoDrm) {
          payload.type = 7;
          payload.channelCode = "ncloud";
          payload.drmCid = "bigcRecordings_2";
        }

        emit("confirm", payload);
      },
      updateType: (dataLabel, checked) => {
        state.types[`is${dataLabel}`] = checked;
        if (dataLabel === "VideoDrm") {
          state.types.isVideo = false;
          state.types.isBrunch = false;
        } else if (dataLabel === "Video") {
          state.types.isVideoDrm = false;
          state.types.isBrunch = false;
        } else {
          state.types.isVideo = false;
          state.types.isVideoDrm = false;
        }
      },
      updateAccessType: (dataLabel, checked) => {
        state[dataLabel] = checked;
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.btn-wrapper {
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

@media (min-width: 768px) {
  .cancel-btn {
    width: 30%;
  }
  .confirm-btn {
    width: 70%;
  }
}

.w-100 {
  width: 100%;
}

.description {
  margin-bottom: 32px;
}

.description li:not(:last-child) {
  margin-bottom: 4px;
}

.hr {
  margin: 2px 0;
}
</style>
