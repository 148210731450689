<template>
  <page-loading v-if="state.pageLoading"></page-loading>
  <div v-if="!state.pageLoading" class="page-container-new">
    <page-header title="콘텐츠">
      <template #headerRightSide>
        <button-basic
          text="콘텐츠 생성하기"
          icon-position="front"
          padding="8px 16px"
          @action="actions.openCreateContentModal()"
        >
          <template #icon>
            <plus-icon fill-color="#ffffff"></plus-icon>
          </template>
        </button-basic>
      </template>
    </page-header>

    <div class="page-wrapper">
      <div class="tab-section">
        <div class="tab-btn-wrapper">
          <button-tab
            v-for="(item, index) in state.types"
            :key="`type-${index}`"
            :text="`${item.label + ' ' + item.count}`"
            :active="state.filters.type === item.type"
            @click="actions.getContents({ type: item.type })"
          ></button-tab>
        </div>
      </div>
      <div class="search-area">
        <input-search-new
          v-model="state.filters.q"
          shape="round"
          placeholder="콘텐츠를 검색해주세요."
          search-type="pressEnter"
          @search="actions.getContents({ q: state.filters.q })"
        ></input-search-new>
      </div>

      <div class="column-head">
        <span class="col1 sub-text-s3 text-gray-second">SNS 콘텐츠</span>
        <span class="col2 sub-text-s3 text-gray-second">상태</span>
        <span class="col3 sub-text-s3 text-gray-second">등록일</span>
      </div>

      <div v-show="state.contentsLoading" class="page-loading-area">
        <page-loading></page-loading>
      </div>

      <div v-show="!state.contentsLoading" class="content-wrapper">
        <ul v-if="state.contents.length > 0" class="content-list">
          <li
            v-for="content in state.contents"
            :key="`channel-content-${content.resourceId}`"
            class="content-item"
            @click="actions.goToContentEdit(content.resourceId)"
          >
            <channel-content-item :content="content"></channel-content-item>
          </li>
        </ul>
        <!--	session empty  -->
        <div v-if="state.contents.length === 0" class="session-empty-card">
          <div class="directory-img">
            <img src="/assets/images/channels/contents-empty.png" width="72" />
          </div>
          <p class="sub-text-s2 text-gray-second">
            생성된 콘텐츠가 없습니다.<br />
            콘텐츠 생성 버튼을 통해 라이브를 추가해보세요!
          </p>
          <button class="creat-btn" @click="actions.openCreateContentModal()">
            <span class="sub-title-s2 text-default">콘텐츠 추가하기</span>
            <arrow-icon class="arrow-icon"></arrow-icon>
          </button>
        </div>
      </div>

      <div
        v-if="state.contentsMeta.pagination.lastPage > 1"
        class="pagination-wrapper"
      >
        <pagination
          :pagination="state.contentsMeta.pagination"
          @getPageData="(page) => actions.getContents({ page: page })"
        ></pagination>
      </div>

      <card-basic v-if="false">
        <div>
          <ul class="tabs">
            <li v-for="(item, index) in state.types" :key="`type${index}`">
              <button-basic
                bg-color="#ffffff"
                :color="
                  state.filters.type === item.type ? '#0D0D10' : '#D0D5DE'
                "
                :text="item.label"
                @action="actions.getContents({ type: item.type })"
              >
                <template #icon>
                  <span
                    :class="
                      state.filters.type === item.type
                        ? 'text-default'
                        : 'text-gray-third'
                    "
                  >
                    {{ item.count }}
                  </span>
                </template>
              </button-basic>
            </li>
          </ul>

          <input-search
            v-model="state.filters.q"
            placeholder="Search Contents"
            search-type="pressEnter"
            @search="actions.getContents({ q: state.filters.q })"
          ></input-search>

          <page-loading v-if="state.contentsLoading"></page-loading>

          <template v-if="!state.contentsLoading">
            <div class="table-card">
              <!--	Empty UI  -->
              <div v-if="state.contents.length === 0" class="empty-section">
                <div class="contents">
                  <h3 class="text-gray-second">
                    {{ state.emptyTitle }}
                  </h3>
                  <button-basic
                    v-if="state.filters.type === $const.contentType.all.type"
                    text="피드 생성하기"
                    @action="actions.openCreateContentModal()"
                  ></button-basic>
                </div>
              </div>

              <div v-if="state.contents.length > 0">
                <table class="table">
                  <tbody>
                    <tr
                      v-for="content in state.contents"
                      :key="content.resourceId"
                    >
                      <td>
                        <router-link
                          :to="`/console/contents/${content.resourceId}`"
                        >
                          <div
                            class="td-1"
                            @click="actions.goToContentEdit(content.resourceId)"
                          >
                            <img :src="content.featuredImage" />

                            <div class="txt-area">
                              <h4 style="max-width: 350px">
                                {{ content.title }}
                              </h4>
                              <span>
                                {{
                                  dateFormat(
                                    content.publishedAt,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                }}
                              </span>
                              <p>
                                {{ (content.likersCount, content.comments) }}
                              </p>
                            </div>
                          </div>
                        </router-link>
                      </td>

                      <td>
                        <span v-if="content.isPaid"> 유료 </span>
                      </td>

                      <td>
                        <button-basic
                          :text="
                            content.channelCode === 'brunch' ? 'brunch' : ''
                          "
                          bg-color="transparent"
                          @action="Helper.goToExternalUrl(content.url)"
                        >
                          <template #icon>
                            <i
                              v-if="content.channelCode === 'youtube'"
                              class="fab fa-youtube fa-2x text-danger"
                            ></i>
                          </template>
                        </button-basic>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              v-if="state.contentsMeta.pagination.lastPage > 1"
              class="pagination-wrapper"
            >
              <pagination
                :pagination="state.contentsMeta.pagination"
                @getPageData="(page) => actions.getContents({ page: page })"
              ></pagination>
            </div>
          </template>
        </div>
      </card-basic>
    </div>
  </div>
  <contents-setting-info-modal
    v-if="state.showCreateContentModal"
    warning-title="콘텐츠 추가하기"
    confirm-text="추가"
    cancel-text="취소"
    @confirm="
      (payload) => {
        actions.createContent(payload);
      }
    "
    @hideModal="actions.closeCreateContentModal()"
  ></contents-setting-info-modal>
</template>

<script>
import { computed, getCurrentInstance, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { dateFormat } from "@/helper/date";
import Helper from "@/helper";
import Pagination from "../../../components/console/pagination/Pagination";
import PageLoading from "../../../components/console/loadings/PageLoading";
import { useRouter } from "vue-router";
import PageHeader from "../../../components/console/headers/PageHeader";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import PlusIcon from "../../../components/console/icons/PlusIcon";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import ChannelContentItem from "./ChannelContentItem/ChannelContentItem";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ContentsSettingInfoModal from "@/pages/console/ContentsList/ContentsSettingInfoModal/ContentsSettingInfoModal.vue";

export default {
  name: "ContentsList",
  components: {
    ContentsSettingInfoModal,
    ArrowIcon,
    CardBasic,
    PlusIcon,
    ButtonBasic,
    PageHeader,
    PageLoading,
    Pagination,
    ButtonTab,
    ChannelContentItem,
    InputSearchNew,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      contents: computed(() => {
        return store.getters["contents/contents"];
      }),
      contentsMeta: computed(() => {
        return store.getters["contents/contentsMeta"];
      }),
      types: computed(() => {
        return store.getters["contents/types"];
      }),
      filters: {
        q: "",
        type: null,
        page: store.getters["contents/contentsMeta"].pagination.currentPage,
      },
      pageLoading: false,
      contentsLoading: false,
      emptyTitle: computed(() => {
        if (state.filters.q) {
          return `"${state.filters.q}"에 대한 검색결과가 없습니다.`;
        }

        if (state.filters.type === proxy.$const.contentType.all.type) {
          if (state.user.userProfile.displayName) {
            return `${state.user.userProfile.displayName}님의 첫번째 콘텐츠를 등록해 보세요`;
          } else {
            return "첫번째 콘텐츠를 등록해 보세요!";
          }
        } else {
          const contentType = Object.values(proxy.$const.contentType).find(
            (item) => {
              const filterType = state.filters.type ? state.filters.type : null;
              return filterType === item.type;
            }
          );

          return `${contentType.label} 콘텐츠가 없습니다.`;
        }
      }),
      showCreateContentModal: false,
    });

    onBeforeMount(() => {
      state.pageLoading = true;
      store.dispatch("contents/getContents").then(() => {
        state.pageLoading = false;
      });
    });

    const actions = {
      goToContentEdit: (resourceId) => {
        let contentResourceId = resourceId;
        router.push({
          path: `/console/contents/${contentResourceId}/edit`,
        });
      },
      getContents: async (params) => {
        state.contentsLoading = true;

        state.filters = { ...state.filters, ...params };

        await store.dispatch("contents/getContents", state.filters).then(() => {
          state.contentsLoading = false;
        });
      },
      createContent: (payload) => {
        store.dispatch("contents/postContent", payload).then(() => {
          const resourceId = store.getters["contents/content"].resourceId;

          router.push({
            name: "console.contents.edit",
            params: {
              contentResourceId: resourceId,
            },
          });
        });
      },
      openCreateContentModal: () => {
        state.showCreateContentModal = true;
      },
      closeCreateContentModal: () => {
        state.showCreateContentModal = false;
      },
    };
    return { state, actions, dateFormat, Helper, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
